import React, {Component} from 'react';
import PropTypes from "prop-types";

class HighlightedText extends Component {

    name = () => {
        const {text, searchText} = this.props;
        const searchLowered = searchText.toLowerCase();
        const nameLowered = text.toLowerCase();
        const searchSize = searchLowered.length;
        const splitLowered = nameLowered.split(searchLowered);
        const newName = [];
        let start = 0;
        splitLowered.forEach((o = "", i) => {
            console.log(start, o.length, text.substr(start, o.length));
            newName.push(text.substr(start, o.length));
            start += o.length;
            newName.push(<span className={"color-success"}>{text.substr(start, searchSize).replace(/ /g, '\u00a0')}</span>)
            start += Math.max(0, searchSize);
        });
        return newName;
    };

    render() {
        const {searchText, text} = this.props;
        if (!searchText) {
            return text;
        } else {
            return this.name();
        }
    }
}

HighlightedText.propTypes = {
    text: PropTypes.string.isRequired,
    searchText: PropTypes.string.isRequired,
};

export default HighlightedText;

