import React from "react";
import LabeledInput from "./LabeledInput";
import Proptypes from "prop-types";

export default class ReduxLabeledInput extends React.PureComponent {

    onChange = (value) => {
        this.props.input.onChange(value)
    };

    onBlur = value => {
        this.props.input.onBlur(value);
    };

    render() {
        const {label, type, disabled, id, input: {value}, meta: {touched, error, warning}, unit, required, optionalProps, className, min, autofocus, placeholder} = this.props;
        return (
            <LabeledInput className={className} required={required} disabled={disabled} label={label} type={type}
                          onChange={value => this.onChange(value)} id={id} value={value}
                          error={touched && error ? error : ""} unit={unit} optionalProps={optionalProps}
                          placeholder={placeholder} warning={touched && warning ? warning : ""}
                          min={min ? min : 0} autofocus={autofocus}
                          onBlur={this.onBlur}/>
        )
    }

}

ReduxLabeledInput.propTypes = {
    label: Proptypes.string.isRequired,
    type: Proptypes.string.isRequired,
    unit: Proptypes.string,
    id: Proptypes.string,
    optionalProps: Proptypes.object
};

ReduxLabeledInput.defaultProps = {
    optionalProps: {}
};
