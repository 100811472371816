import React from "react";
import PropTypes from "prop-types";
import "./_json-to-table.scss";
import {isBoolean, isNumber, isObject} from "lodash";

const defaultCellRenderer = (value) => {
    return isBoolean(value) ? (value ? '✓' : '✗') : React.isValidElement(value) ? value : isObject(value) ? JSON.stringify(value) : value;
}

export default class JsonToTable extends React.Component {


    getData = (props = this.props) => {
        const {json = []} = props;
        let arr = this.isArray(json) ? json : this.objectToArray(json);
        if (!this.isArray(arr)) {
            arr = [];
        }
        return arr;
    };

    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]' && !React.isValidElement(obj)
    }

    isArray(obj) {
        return Array.isArray(obj)
    }

    arrayToTable = (array) => {
        console.log(array);
        const _recur = (arr, tableDepth = 1, path = "") => {
            let fieldSet = new Set();
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (this.isArray(item)) {
                    return _recur(item, tableDepth + 1, path)
                } else if (this.isObject(item)) {
                    Object.keys(item).forEach(j => fieldSet.add(j));
                }
            }

            let fields = Array.from(fieldSet);
            let rows = arr.map(() => []);
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                let tmpPath = "";
                if (this.isObject(item)) {
                    for (let j = 0; j < fields.length; j++) {
                        let value = item[fields[j]];
                        if (this.isArray(value)) {
                            if (tmpPath === "") tmpPath = 0;
                            rows[i][j] = _recur(value, tableDepth + 1, [path, tmpPath].filter(o => o !== "").join("."));
                            if (isNumber(tmpPath)) tmpPath++;
                        } else if (this.isObject(value)) {
                            rows[i][j] = _recur(this.objectToArray(value, value.$_key), tableDepth + 1, [path, value.$_key].filter(o => o !== "").join("."));
                        } else {
                            if (fields[j] === '$_key') {
                                tmpPath = value;
                            }
                            rows[i][j] = {
                                isKey: fields[j] === '$_key',
                                value,
                                path: fields[j] !== '$_key' ? [path, tmpPath].filter(o => o !== "").join(".") : ""
                            };

                        }
                    }
                } else {
                    rows[i][fields.length] = {isKey: false, value: item, path: path};
                }
            }

            return this.tableToHtml(fields, rows, tableDepth)
        };

        return _recur(array)
    };

    objectToArray = (json, oldKey = "") => {
        if (this.isObject(json)) {
            let arr = [];
            for (let key in json) {
                arr.push({
                    $_key: key,
                    $_value: this.objectToArray(json[key], [oldKey, key].filter(o => o !== "").join("."))
                });
            }
            return arr
        } else if (this.isArray(json)) {
            let arr = [];
            for (let item of json) {
                if (this.isObject(json)) {
                    console.log(JSON.stringify(json))
                    arr.push(this.objectToArray(json, oldKey));
                } else {
                    console.log(JSON.stringify(item), ' LDLDdd')
                    arr.push({
                        $_key: arr.length,
                        $_value: this.objectToArray(item, [oldKey, arr.length].filter(o => o !== "").join("."))
                    });
                }
            }
            return arr
        }
        return json
    };

    tableToHtml = (fields, rows, tableDepth) => {
        const {cellRenderer} = this.props;
        return (<table cellSpacing="0" className={`depth-${tableDepth}`}>
            <thead>
            {
                fields.filter(f => !f.startsWith('$_'))
                    .map(f => <th>{f}</th>)
            }
            </thead>
            <tbody>{rows
                .map(row => {
                    let tds = [];
                    for (let i = 0; i < row.length; i++) {
                        const v = row[i] || '';
                        if (this.isObject(v) && v.type !== "table") {
                            tds.push(<td>{
                                cellRenderer(v.value, {path: v.path, defaultRenderer: defaultCellRenderer})
                            }</td>);
                        } else {
                            tds.push(<td>{cellRenderer(v, {defaultRenderer: defaultCellRenderer})}</td>)
                        }
                    }
                    return <tr>{tds}</tr>
                })
            }
            </tbody>
        </table>)
    };

    render() {
        const className = ["json-to-table", this.props.className].filter(o => o).join(" ");
        const {style} = this.props;
        const data = this.getData(this.props);
        return (
            <div style={style} className={className}>
                {this.arrayToTable(data)}
            </div>
        );
    }

}

JsonToTable.propTypes = {
    className: PropTypes.string,
    json: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
    cellRenderer: PropTypes.func,
    style: PropTypes.object
};


JsonToTable.defaultProps = {
    className: "",
    json: [],
    cellRenderer: defaultCellRenderer
};
