import React from "react";
import PropTypes from "prop-types"
import "./_item-picker.scss"
import {ReactSVG} from 'react-svg';
import {isEqual} from "lodash";
import Tooltip from "../tooltip/Tooltip";
import moment from "moment";
import i18n from "../../../i18n";
import {isOverflown} from "../../../utils/DOMUtils";

export default class ItemPicker extends React.Component {

    onhover = (e) =>{
        e.preventDefault();
        if(isOverflown(e.currentTarget)){
            e.currentTarget.setAttribute("style","overflow:visible; width:max-content !important;");
        }
    };
    onleave=(e)=>{
        e.preventDefault();
        e.currentTarget.setAttribute("style","");
    };

    renderOption = ({value, svgPath, label, disabled, drafts}, selected, showLabels, getDraftTooltip) => (
        <div className={'option-item'}
             style={{flex: `0 0 ${100 / this.props.itemsInRow}%`}}>
            <div
                className={`circle-container${isEqual(selected, value) ? ' selected' : ''}${disabled ? " disabled" : ""}`}
                onClick={!disabled ? () => this.props.onChange(value, label) : null}>
                <ReactSVG src={svgPath} loading={() => <svg viewBox="0 0 270.93334 270.93334"/>}/>
            </div>
            {
                showLabels && <div className={'label'} onMouseOver={(e)=>this.onhover(e)} onMouseLeave={(e)=>this.onleave(e)}
                                   onClick={!disabled ? () => this.props.onChange(value, label) : null}>{label}</div>
            }
            {
                drafts && drafts.length > 0 &&
                <Tooltip tooltipContent={this.props.getDraftTooltip(drafts)} type={"info"}>
                    <div className="draft">
                        <i className="fas fa-file"/>
                    </div>
                </Tooltip>
            }
        </div>
    );

    render() {
        const {options, required, value, showLabels, label} = this.props;
        return (
            <React.Fragment>
                {
                    label && <label>{label}{required && <sup>*</sup>}</label>
                }
                <div className="fetura-item-picker">
                    {
                        options.map(option => (
                            this.renderOption(option, value, showLabels)
                        ))
                    }
                </div>
            </React.Fragment>

        );
    }

}

ItemPicker.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object]),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
        svgPath: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        drafts: PropTypes.array,
    })),
    label: PropTypes.string,
    onChange: PropTypes.func,
    showLabels: PropTypes.bool,
    required: PropTypes.bool,
    itemsInRow: PropTypes.number,
    getDraftTooltip: PropTypes.func,
};

ItemPicker.defaultProps = {
    optionsInSingleRow: 4,
    showLabels: false,
    onChange: () => {
    },
    itemsInRow: 5,
    getDraftTooltip: (drafts) => {
        return (
            <>
                <div>
                    {i18n.t("foundDrafts")}
                </div>
                {
                    drafts.map((draft, index) => <div key={index}>{moment(draft.time).format("DD.MM.YYYY")}</div>)
                }
            </>
        );
    }
};