import React from "react";
import PropTypes from "prop-types";
import "./_icon-value.scss";
import {ReactSVG} from 'react-svg'
import Tooltip from "../tooltip/Tooltip";
import {isNil} from "lodash"

export default class IconValue extends React.Component {

    render() {
        const {icon, value, name, showWhenValueNotFound, disabledTooltip} = this.props;
        if (!showWhenValueNotFound && (isNil(value) || value.includes("-"))) return null;
        return (
            <div className="fetura-icon-value">
                <div className="fetura-icon-value-container">
                    <Tooltip tooltipContent={!disabledTooltip ? name : null} placement={"auto-end"}>
                        <div className="fetura-icon-value-svg"><ReactSVG src={icon}/></div>
                    </Tooltip>
                </div>
                <Tooltip tooltipContent={!disabledTooltip ? name : null} placement={"auto-end"}>
                    <span>{value}</span>
                </Tooltip>
            </div>
        );
    }

}

IconValue.propTypes = {
    icon: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    showWhenValueNotFound: PropTypes.bool,
    disabledTooltip: PropTypes.bool,
}

IconValue.defaultProps = {
    showWhenValueNotFound: true
}