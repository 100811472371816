import React from "react";
import ItemPicker from "./ItemPicker";
import PropTypes from "prop-types"

export default class ReduxItemPicker extends React.Component {

    onChange = (value) => {
        this.props.input.onChange(value)
    };

    render() {
        const {required, meta: {error, warning}, input: {value}, showLabels, options, label, itemsInRow} = this.props;
        return (
            <ItemPicker required={required} label={label} error={error} warning={warning} value={value}
                        options={options} showLabels={showLabels}
                        onChange={this.onChange} itemsInRow={itemsInRow}/>
        )
    }

}

ReduxItemPicker.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
        svgPath: PropTypes.string.isRequired,
        label: PropTypes.string
    })),
    label: PropTypes.string,
    showLabels: PropTypes.bool,
    required: PropTypes.bool,
    itemsInRow: PropTypes.number
};