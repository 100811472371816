import React from "react";
import PropTypes from "prop-types"
import "./_highlight.scss"

export default class Highlight extends React.Component {

    render() {
        const {highlightColor, children, onClick, style} = this.props;
        return (
            <span style={style} className={`fetura-highlight ${highlightColor} ${onClick ? 'pointer' : ''}`}
                  onClick={onClick}>{children}</span>
        );
    }

}

Highlight.propTypes = {
    highlightColor: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""]),
    onClick: PropTypes.func,
    style: PropTypes.object,
};

Highlight.defaultProps = {
    highlightColor: "",
    style: {}
};
