import React from "react";
import PropTypes from "prop-types"
import "./_header.scss"
import _ from "lodash"
import Button from "../button/Button";
import Menu from "../menu/Menu";
import MenuItem from "../menu/MenuItem";
import {isMobile} from "../../../utils/MobileUtils";
import {connect} from "react-redux";
import Tooltip from "../tooltip/Tooltip";

@connect(state => ({
    lang: state.language.lang.lang
}))

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: props.active === undefined ? 0 : props.active,
            maxItems: props.tabs.length,
            widthsOfItems: [],
            event: null,
            mobile: isMobile()
        };
        this.tabs = [];
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState) || !_.isEqual(this.props.text, nextProps.text) || !_.isEqual(this.props.lang, nextProps.lang) || !_.isEqual(this.props.tabs, nextProps.tabs);
    }

    getTabOffset = () => {
        try {
            return (document.getElementsByClassName("menu-button-container").item(0).clientWidth || 0);
        } catch (e) {
            console.error(e);
            return 0;
        }
    };

    onTabsContainerResize = e => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            try {
                let tabContainerWidth = this.tabContainer ? this.tabContainer.clientWidth : 0;
                let width = 0;
                let maxItems = 0;
                for (let i = 0; i < this.tabs.length; i++) {
                    let w = this.tabs[i].clientWidth;
                    width += w;
                    if (width < (tabContainerWidth - this.getTabOffset())) {
                        maxItems++;
                    }
                }
                this.setState({
                    maxItems,
                    timestamp: e.timeStamp
                })
            } catch (e) {
                console.error(e);
            }
        }, 100);
    };

    componentDidMount() {
        window.addEventListener("resize", this.onTabsContainerResize);
        this.onTabsContainerResize({timestamp: 0});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.active !== nextProps.active) {
            this.setState({
                active: nextProps.active
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onTabsContainerResize);
        clearTimeout(this.timer);
    }

    onTabClick = (key) => {
        this.setState({
            active: key
        });
        this.props.onTabChange(key);
    };

    onTabsButtonClick = e => {
        this.setState({
            event: e
        })
    };

    renderTabs = () => {
        const {tabs} = this.props;
        const {maxItems, event, timestamp} = this.state;
        let renderedTabs = [], menuTabs = [];
        for (let i = 0; i < tabs.length; i++) {
            renderedTabs.push((
                <Button
                    timestamp={timestamp}
                    type={"button"}
                    buttonStyle={"text"}
                    className={(this.state.active === i ? "active" : "")}
                    onClick={() => this.onTabClick(i)}
                    buttonRef={ref => this.tabs[i] = ref}
                    key={i}
                >
                    {tabs[i].name}
                </Button>
            ));
        }
        if (maxItems !== tabs.length) {
            for (let i = maxItems; i < tabs.length; i++) {
                menuTabs.push((
                    <MenuItem
                        timestamp={timestamp}
                        key={`menuitem_${i}`}
                        selected={this.state.active === i}
                        onClick={() => this.onTabClick(i)}
                        buttonColor={""}
                    >
                        {tabs[i].name}
                    </MenuItem>
                ));
            }
        }
        return (
            <React.Fragment>
                {renderedTabs}
                {
                    menuTabs.length !== 0 &&
                    <>
                        <div className={"menu-button-container"}>
                            <Button buttonStyle={"text"} className={"menu-button"}
                                    icon={<i className="fas fa-ellipsis-v"/>}
                                    onClick={this.onTabsButtonClick}/>
                        </div>
                        <Menu event={event}>
                            {menuTabs}
                        </Menu>
                    </>
                }
            </React.Fragment>
        )
    };

    getTooltipContent() {
        const {text} = this.props;
        let array = Array.isArray(text) ? text : [text];
        for (let item of array) {
            if (typeof item !== "object") return item;
            let t = item.props.children.find(child => typeof child !== "object");
            if (t) return t;
        }
    }

    render() {
        const {text, tabs} = this.props;
        const {mobile} = this.state;
        let cName = mobile ? "mobile" : null;
        return (
            <React.Fragment>
                <header className={cName} id={"app-header"}>
                    <h2>
                        {
                            mobile &&
                            <Tooltip tooltipContent={this.getTooltipContent()} placement={"bottom"}>
                                <div>{text}</div>
                            </Tooltip>
                        }
                        {
                            !mobile && <div>{text}</div>
                        }
                    </h2>
                    {
                        tabs.length > 0 &&
                        <div className="tabs" ref={ref => this.tabContainer = ref}>
                            {this.renderTabs()}
                        </div>
                    }
                </header>
            </React.Fragment>
        );
    }

}

Header.propTypes = {
    text: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    })),
    onTabChange: PropTypes.func,
    active: PropTypes.number
};

Header.defaultProps = {
    tabs: [],
    onTabChange: (key) => {
    }
};
