import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";

export default class ReduxInput extends React.PureComponent {

    onChange = (value) => {
        this.props.input.onChange(value)
    };

    onBlur = (e) => {
        this.props.input.onBlur(e)
    };

    render() {
        const {type, id, disabled, readOnly, placeholder, unit, className, icon, onClick, meta: {touched, error, warning}, input: {value}, showIconOnErrorOnWarning, min} = this.props;
        return (
            <Input type={type}
                   onChange={value => this.onChange(value)}
                   onBlur={value => this.onBlur(value)}
                   id={id}
                   value={value}
                   error={touched && error ? error : ""}
                   warning={touched && warning ? warning : ""}
                   unit={unit}
                   disabled={disabled}
                   readOnly={readOnly}
                   placeholder={placeholder}
                   className={className}
                   icon={icon}
                   onClick={onClick}
                   showIconOnErrorOnWarning={showIconOnErrorOnWarning}
                   min={min ? min : 0}
            />
        )
    }
}

ReduxInput.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    placeholder: PropTypes.node,
    unit: PropTypes.string,
    showIconOnErrorOnWarning: PropTypes.bool
};