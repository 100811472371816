import React from "react";
import PropTypes from "prop-types"
import Input from "../Input";
import {myID} from "../../../../libs/generateID";

export default class LabeledInput extends React.PureComponent {

    onChange = (value) => {
        this.props.onChange(value);
    };

    onBlur = e => {
        this.props.onBlur(e);
    };


    render() {
        const {id, label, type, disabled, value, error, unit, required, optionalProps, className, frName, name, onChangeWithEvent, min, autofocus, placeholder, warning} = this.props;
        return (
            <React.Fragment>
                <label htmlFor={id}>{label}{required && <sup>*</sup>}</label>
                <Input className={className} disabled={disabled} type={type} id={id}
                       onChange={value => this.onChange(value)} value={value} frName={frName}
                       min={min} error={error} unit={unit} optionalProps={optionalProps} onBlur={this.onBlur}
                       name={name} onChangeWithEvent={onChangeWithEvent} autofocus={autofocus} placeholder={placeholder}
                       warning={warning}
                />
            </React.Fragment>
        );
    }

}

LabeledInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    unit: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    frName: PropTypes.string,
    name: PropTypes.string,
    onChangeWithEvent: PropTypes.bool
};

LabeledInput.defaultProps = {
    id: myID(),
    required: false,
    onChange: (text) => {
    },
    onBlur: (text) => {
    },
    optionalProps: {}
};
