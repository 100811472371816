import React from "react";
import PropTypes from "prop-types"
import "./_input.scss"
import {myID} from "../../../libs/generateID";
import Tooltip from "../tooltip/Tooltip";
import Button from "../button/Button";

export default class Input extends React.PureComponent {

    findClosestsInXInput(array, rect) {
        let diff, closestInput;
        for (let input of array) {
            let inputRect = input.getBoundingClientRect();
            if (!closestInput) {
                diff = Math.abs(rect.x - inputRect.x);
                closestInput = input;
            } else {
                let diffToInput = Math.abs(rect.x - inputRect.x);
                if (diffToInput < diff) {
                    diff = diffToInput;
                    closestInput = input;
                }
            }
        }
        return closestInput;
    }

    keyDownEventListener = e => {
        let shouldProceed = true;
        if (this.props.onKeyDown) {
            shouldProceed = this.props.onKeyDown(e);
        }
        // jezeli metoda zwroci false to nie zostanie wykonane przeskakiwanie do innych inputow
        // uzyte glownie w selectach zeby mozna bylo przeskakiwac wartosci
        if (shouldProceed) {
            if (e.which === 38 || e.which === 40 && ["datetime-local", "number"].includes(this.props.type))
                e.preventDefault();
            // wyszukiwanie elementow ponizej i ich focus po znalezieniu
            if (e.which === 40) {
                let inputs = [...document.getElementsByTagName("input")];
                let rect = this.input.getBoundingClientRect();
                let lowerInputs = inputs.filter(item => {
                    let r1 = item.getBoundingClientRect();
                    return r1.y > rect.y;
                });
                let closestInput = this.findClosestsInXInput(lowerInputs, rect);
                if (closestInput) closestInput.select();
            }
            if (e.which === 38) {
                let inputs = [...document.getElementsByTagName("input")];
                let rect = this.input.getBoundingClientRect();
                let higherInputs = inputs.filter(item => {
                    let r1 = item.getBoundingClientRect();
                    return r1.y < rect.y;
                });
                higherInputs.sort((a, b) => b.getBoundingClientRect().y - a.getBoundingClientRect().y);
                let closestInput = this.findClosestsInXInput(higherInputs, rect);
                if (closestInput) closestInput.select();
            }
        }
    };

    componentDidMount() {
        //disable up, down, right, left arrow keys
        this.input.addEventListener("keydown", this.keyDownEventListener, {passive: true});
    }

    componentWillUnmount() {
        this.input.removeEventListener("keydown", this.keyDownEventListener)
    }

    onChange = e => {
        try {
            const {type, onChangeWithEvent} = this.props;
            if (type === "file") {
                this.props.onChange(e);
            } else {
                let text = e.target.value;
                this.props.onChange(onChangeWithEvent ? e : text);
            }
        } catch (e) {
            console.error(e)
        }

    };

    onClick = e => {
        e.stopPropagation();
        this.props.onClick && this.props.onClick(e);
    };

    getClassname() {
        const {className, error, unit, warning} = this.props;
        let cName = "fetura-input";
        if (error) cName += " error";
        if (warning) cName += " warning";
        if (unit) cName += " unit";
        if (className) cName += ` ${className}`;
        return cName
    }

    disableMouseWheel = event => {
        event.preventDefault();
    };

    onBlur = e => {
        const {type} = this.props;
        this.props.onBlur && this.props.onBlur(e);
        if (type === "number") {
            this.input.removeEventListener("mousewheel", this.disableMouseWheel);
        }
    };

    onFocus = e => {
        const {onFocus, type} = this.props;
        if (onFocus) onFocus(e);
        if (type === "number") {
            if (this.input) {
                this.input.addEventListener("mousewheel", this.disableMouseWheel);
            }
        }
    };

    selectFile = () => {
        const {id} = this.props;
        document.getElementById(id).click();
    };

    onCreate = (type, id, disabled, readOnly, value, placeholder, min, optionalProps, name, lang, autofocus, style) => {
        const {frName} = this.props;
        try {
            if (type === "file") {
                return (<div>
                    <Button type={"button"} buttonColor={"primary"} onClick={this.selectFile}>{frName}</Button>
                    <input type={type} id={id} disabled={disabled} readOnly={readOnly} value={value}
                           style={{display: "none"}} ref={ref => this.input = ref} min={min}
                           onChange={this.onChange} {...optionalProps} name={name}/>
                </div>);
            } else {
                return React.createElement(type === "textarea" ? "textarea" : "input", {
                    type: type,
                    id: id,
                    autoFocus: autofocus,
                    disabled: disabled,
                    readOnly: readOnly,
                    value: value,
                    placeholder: placeholder,
                    ref: ref => this.input = ref,
                    onBlur: this.onBlur,
                    min: min,
                    ...optionalProps,
                    pattern: type === "number" ? "\\d*" : undefined,
                    inputMode: type === "number" ? "numeric" : undefined,
                    onFocus: this.onFocus,
                    onChange: this.onChange,
                    name: name,
                    lang,
                    style
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {optionalProps, type, id, error, disabled, readOnly, value, placeholder, unit, warning, showIconOnErrorOnWarning, min, icon, name, lang, autofocus, style} = this.props;
        return (
            <div className={this.getClassname()} data-tip={!showIconOnErrorOnWarning ? (error || warning) : null}
                 onClick={this.onClick}>
                {
                    this.onCreate(type, id, disabled, readOnly, value, placeholder, min, optionalProps, name, lang, autofocus, style)
                }
                {
                    unit &&
                    <span>{unit}</span>
                }
                {
                    showIconOnErrorOnWarning && (error || warning) &&
                    <Tooltip tooltipContent={error || warning} type={error ? "error" : "warning"}>
                        <div className="input-icon">
                            <i className={error ? "fas fa-exclamation-circle error" : "fas fa-exclamation-circle warning"}/>
                        </div>
                    </Tooltip>
                }
                {
                    !(showIconOnErrorOnWarning && (error || warning)) &&
                    <div className="input-icon">
                        {icon}
                    </div>
                }
            </div>
        );
    }

}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    initialValue: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.string,
    warning: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.node,
    placeholder: PropTypes.node,
    autofocus: PropTypes.bool,
    unit: PropTypes.string,
    showIconOnErrorOnWarning: PropTypes.bool,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    optionalProps: PropTypes.object,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    inputRef: PropTypes.object,
    frName: PropTypes.string,
    name: PropTypes.string,
    onChangeWithEvent: PropTypes.bool,
    lang: PropTypes.string,
    style: PropTypes.object,
};

PropTypes.defaultProps = {
    autofocus: false,
    className: "",
    initialValue: "",
    id: myID(),
    onChange: (text) => {
    },
    readOnly: false,
    onClick: (e) => {
    },
    value: "",
    placeholder: "",
    onBlur: () => {
    },
    optionalProps: {},
    onFocus: () => {
    },
    onKeyDown: () => {
    },
};
