import React from "react";
import PropTypes from "prop-types";
import "./_info-box.scss";
import _ from "lodash";

export default class InfoBox extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps);
    }

    render() {
        const {boxColor, fixed, onClick, children, className: classNameProps} = this.props;
        const className = ["fetura-info-box", boxColor ? `${boxColor}` : "", fixed ? "fixed" : "", onClick ? "pointer" : "", classNameProps].filter(o => !!o).join(" ");
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    }

}

InfoBox.propTypes = {
    boxColor: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""]),
    fixed: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};

InfoBox.defaultProps = {
    boxColor: "info",
    fixed: false
};